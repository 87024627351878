// @flow

export const queryParams = {
  ENV: 'env',
  LOCALE: 'locale',
};

export const asyncStorageKeys = Object.freeze({
  GIGYA_ENVIRONMENT: 'GigyaEnvironment',
});

export const gigyaEnvironments = {
  DEFAULT: 'default',
  TICKETING: 'ticketing',
};
