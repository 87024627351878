// @flow

import React, { type Node as ReactNode } from 'react';
import Button from '@material-ui/core/Button';

type Props = {|
  children: ReactNode,
  onClose: () => void,
|};

export const HelpModal = (props: Props) => {
  return (
    <div style={styles.container}>
      {props.children}
      <br />
      <div onClick={() => {}}>
        <Button variant="contained" onClick={() => props.onClose()} style={{ margin: 15 }}>
          CLOSE
        </Button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    padding: 20,
    zIndex: 2000000000, // Gigya z-index for the overlay is 1000000000
    backgroundColor: 'white',
  },
};
