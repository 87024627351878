// @flow

import * as React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import Chip from '@material-ui/core/Chip';

import { StyledTableRow, StyledTableCell } from './tableElements';
import { HEALTH_STATUS } from '../config/healtStatus';

type Props = {|
  services: ServiceState[],
|};

export class TableService extends React.Component<Props, {}> {
  showHealthStatus(status: HealthStatus) {
    switch (status) {
      case HEALTH_STATUS.UP:
        return (
          <Chip
            className="state__up"
            label="Running"
            icon={<CheckCircleIcon htmlColor="#3e9452" />}
            size="small"
          />
        );
      case HEALTH_STATUS.NA:
        return (
          <Chip
            className="state__off"
            label="Offline"
            icon={<LinkOffIcon htmlColor="#999" />}
            size="small"
          />
        );
      default:
        return (
          <Chip
            className="state__down"
            label="Down"
            icon={<CancelIcon htmlColor="#9e1c27" />}
            size="small"
          />
        );
    }
  }

  showSwaggerIcon(swaggerUrl: string) {
    if (!swaggerUrl) return null;
    return (
      <Tooltip className="service__swagger" title="Swagger" aria-label="swagger" arrow="true">
        <IconButton
          size="small"
          onClick={() => {
            window.open(swaggerUrl, '_blank');
          }}
        >
          <SettingsEthernetIcon />
        </IconButton>
      </Tooltip>
    );
  }

  render() {
    return (
      <Table aria-label="simple table">
        <TableHead>
          <TableRow style={{ backgroundColor: 'black' }}>
            <StyledTableCell>SERVICE NAME</StyledTableCell>
            <StyledTableCell>INTEGRATION</StyledTableCell>
            <StyledTableCell>STAGING</StyledTableCell>
            <StyledTableCell>PRODUCTION</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.services.length > 0 &&
            this.props.services.map((service) => (
              <StyledTableRow key={service.name}>
                <StyledTableCell component="th" scope="row">
                  {service.displayName}
                </StyledTableCell>
                <StyledTableCell>
                  {this.showHealthStatus(service.status.INT)}
                  {this.showSwaggerIcon(service.swagger.INT)}
                </StyledTableCell>
                <StyledTableCell>
                  {this.showHealthStatus(service.status.STAGING)}
                  {this.showSwaggerIcon(service.swagger.STAGING)}
                </StyledTableCell>
                <StyledTableCell>
                  {this.showHealthStatus(service.status.PROD)}
                  {this.showSwaggerIcon(service.swagger.PROD)}
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    );
  }
}
