// @flow

import React, { Component, type Node as ReactNode } from 'react';
import type { RouterHistory } from 'react-router-dom';
import moment from 'moment';
import { runGAFirebaseApp, stopGAFirebaseApp } from './analytics-checker';
import './App.css';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import colorizeString from './colorizeString';
import EventBar from './EventBar';
import { getCurrentGigyaEnvironment } from './services/context';
import { HelpModal } from './components';

import TvMockupScreen from './TvMockupScreen';
import DisplayScreen from './DisplayScreen';
import PersonalizeLoginRegister from './screens/personalize-login-register/PersonalizeLoginRegister';

const IdpManager = window.IdpManager;
const eventEmitter = IdpManager.getEventEmitter();
const gigyaEventEmimtter = IdpManager._getGigyaEventEmitter();

type Event = {
  name?: string,
  reason: ?string,
  toPlainObject: () => Object,
};

type Props = {|
  history: RouterHistory,
|};

type State = {|
  showUserSettings: boolean,
  showLiteRegistration: boolean,
  showMinimalLiteRegistration: boolean,
  showDemoTvPage: boolean,
  redirectUrl: string,
  events: Array<{
    id: number,
    context: string,
    event: Event,
    date: string,
  }>,
  user: ?{
    profile?: {
      email: string,
    },
    isLogged: () => boolean,
    toPlainObject: () => Object,
  },
  debugMode: boolean,
  firebase: boolean,
  helpModalContent?: ReactNode,
  openModal: boolean,
|};

const IDP_DEMO_LOCAL_STORAGE_KEY = 'idpDebugMode';
const IDP_DEMO_LOCAL_STORAGE_KEY_FIREBASE = 'idpFirebase';
const getDebugMode = (): boolean => localStorage.getItem(IDP_DEMO_LOCAL_STORAGE_KEY) === '1';
const getFirebase = (): boolean =>
  localStorage.getItem(IDP_DEMO_LOCAL_STORAGE_KEY_FIREBASE) === '1';

class App extends Component<Props, State> {
  state = {
    showUserSettings: false,
    showLiteRegistration: false,
    showMinimalLiteRegistration: false,
    showDemoTvPage: false,
    redirectUrl: '',
    events: [],
    user: null,
    debugMode: getDebugMode(),
    firebase: getFirebase(),
    helpModalContent: undefined,
    openModal: false,
  };

  addEvent = (event: Event, context: ?string) => {
    if (!getDebugMode()) return;

    const id = Math.random();
    const date = moment().format('x');
    const newEvent = { event, context: context || 'global', id, date };
    console.group(event.name);
    console.log(moment().format('LTS'));
    console.log('name: ', newEvent.event.name);
    console.log(
      `context: ${newEvent.context} %c  `,
      `background: #${colorizeString(newEvent.context)};`,
    );

    if (event.reason) {
      console.log('reason: ', event.reason);
    }
    console.log(newEvent.event);
    console.groupEnd();

    this.setState({
      events: [...this.state.events, newEvent],
    });
  };

  componentDidMount() {
    IdpManager.onReady().then((user) => {
      if (user.isLogged()) {
        this.setState({ user });
      }
      if (getFirebase()) runGAFirebaseApp();
    });

    eventEmitter.onAny((name: string, event: Event) => this.addEvent(event));
    eventEmitter.on('user-change', this.updateUser);
    eventEmitter.on('request-close', this.hideHelpModal);
    gigyaEventEmimtter.on('afterScreenLoad', this.revealFieldsForDebug);
  }

  componentWillUnmout() {
    stopGAFirebaseApp();
  }

  revealFieldsForDebug = () => {
    this.revealGigyaMetaFields();
    this.revealUndisplayedFields();
  };

  // reveal Gigya fields hidden because of "type=hidden"
  revealGigyaMetaFields = () => {
    if (this.state.debugMode) {
      const elementsToReveal = document.querySelectorAll('input[type="hidden"]');
      elementsToReveal.forEach((element) => {
        element.setAttribute('type', 'text');
        element.setAttribute('revealed-for-debug', 'true');
        element.setAttribute('placeholder', `[debug] ${element.getAttribute('name') || ''}`);
        element.setAttribute(
          'style',
          'font-size:18px;height:50px;border:0px;border-radius:0px;border-bottom: 2px solid rgba(175,182,188,.4);cursor:not-allowed;',
        );
      });
      return;
    }
    const elementsToHide = document.querySelectorAll('input[revealed-for-debug]');
    elementsToHide.forEach((element) => {
      element.setAttribute('type', 'hidden');
      element.removeAttribute('revealed-for-debug');
    });
  };

  // reveal fields undisplayed because of "display:none"
  // there is a setTimeout to wait asynchronous scripts to finish to create the hidden filds we want to display
  revealUndisplayedFields = () => {
    if (this.state.debugMode) {
      setTimeout(() => {
        const elementsToReveal = document.querySelectorAll(
          '.gigya-screen-dialog-main [style="display: none;"], .gigya-style-modern [style="display: none;"]',
        );
        elementsToReveal.forEach((element) => {
          element.setAttribute('style', 'false');
          const inputChildren = element.querySelectorAll('input');
          inputChildren &&
            inputChildren.forEach((child) => child.setAttribute('style', 'cursor:not-allowed;'));
        });
      }, 1000);
    }
  };

  updateUser = (event: *) => {
    this.setState({ user: event.user });
  };

  attachEvent = (localEventEmitter: *, context: string) => {
    if (!localEventEmitter || !localEventEmitter.onAny) {
      console.warn('You tried to attach events from an invalid event emitter.');
      console.warn('If there is indeed events to attach, check how to access the event emitter');
      return;
    }
    localEventEmitter.onAny((name: string, event: Event) => this.addEvent(event, context));
  };

  toggleDebugMode = (event: SyntheticEvent<HTMLInputElement>) => {
    if (!(event.target instanceof HTMLInputElement)) {
      return;
    }
    const switchValue = !!event.target.checked;
    // $FlowFixMe dirty cast to number using `+` on a boolean
    localStorage.setItem(IDP_DEMO_LOCAL_STORAGE_KEY, +switchValue);
    this.setState({ debugMode: switchValue });
  };

  toggleFirebase = (event: SyntheticEvent<HTMLInputElement>) => {
    if (!(event.target instanceof HTMLInputElement)) {
      return;
    }
    const switchValue = !!event.target.checked;
    // $FlowFixMe dirty cast to number using `+` on a boolean
    localStorage.setItem(IDP_DEMO_LOCAL_STORAGE_KEY_FIREBASE, +switchValue);
    this.setState({ firebase: switchValue });
    if (switchValue) runGAFirebaseApp();
    if (!switchValue) stopGAFirebaseApp();
  };

  showHelpModalWithContent = (content: ReactNode) => {
    this.setState({ helpModalContent: content });
  };

  hideHelpModal = () => {
    this.setState({ helpModalContent: undefined });
  };

  render() {
    const {
      showUserSettings,
      showLiteRegistration,
      showMinimalLiteRegistration,
      showDemoTvPage,
      user,
      redirectUrl,
      openModal,
    } = this.state;

    const defaultOptions = {
      campaign: 'testCampaign',
      redirectUrl,
    };

    return (
      <MuiThemeProvider theme={createMuiTheme({})}>
        <div style={{ display: 'flex' }}>
          <EventBar
            isDebugMode={this.state.debugMode}
            toggleDebugMode={this.toggleDebugMode}
            isFirebase={this.state.firebase}
            toggleFirebase={this.toggleFirebase}
          />
          <div style={{ flex: 1 }}>
            <div className="App">
              <header className="App-header">
                <h1 className="App-title">UEFA - IDP sandbox</h1>
                <h1 className="App-title">{`Gigya environment: ${getCurrentGigyaEnvironment()}`}</h1>
                <div className="App-title-user">
                  {user && user.profile ? '🙂' + user.profile.email : '🔌 You are not logged in'}
                </div>
              </header>
              {showUserSettings && (
                <DisplayScreen
                  attachEvent={this.attachEvent}
                  showScreen={window.IdpManager.showProfile}
                  close={() => this.setState({ showUserSettings: false })}
                />
              )}
              {showLiteRegistration && (
                <DisplayScreen
                  attachEvent={this.attachEvent}
                  showScreen={window.IdpManager.showLiteRegistration}
                  close={() => this.setState({ showLiteRegistration: false })}
                />
              )}
              {showMinimalLiteRegistration && (
                <DisplayScreen
                  attachEvent={this.attachEvent}
                  showScreen={window.IdpManager.showMinimalLiteRegistration}
                  close={() => this.setState({ showMinimalLiteRegistration: false })}
                />
              )}
              {showDemoTvPage && (
                <TvMockupScreen close={() => this.setState({ showDemoTvPage: false })} />
              )}
              {openModal && (
                <PersonalizeLoginRegister
                  close={() => this.setState({ openModal: false })}
                  startScreen="login"
                />
              )}
              {!showUserSettings &&
                !showLiteRegistration &&
                !showMinimalLiteRegistration &&
                !showDemoTvPage && (
                  <div className="App-main">
                    <div className="App-intro">
                      <Button
                        variant="contained"
                        onClick={() => {
                          this.attachEvent(IdpManager.showLogin(defaultOptions), 'login');
                        }}
                        style={{ margin: 15 }}
                      >
                        Login Screen
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => this.setState({ openModal: true })}
                        style={{ margin: 15 }}
                      >
                        Personalize Login
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() =>
                          this.attachEvent(IdpManager.showRegister(defaultOptions), 'register')
                        }
                        style={{ margin: 15 }}
                      >
                        Register Screen
                      </Button>
                      <Button
                        variant="contained"
                        onClick={IdpManager.showLoginRegistrationWithBenefits}
                      >
                        Custom Login Popup
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => this.props.history.push('/embedded')}
                        style={{ margin: 15 }}
                      >
                        Embedded Login/Register
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => this.setState({ showDemoTvPage: true })}
                        style={{ margin: 15 }}
                      >
                        TV Screen
                      </Button>
                      <Button
                        variant="contained"
                        onClick={IdpManager.startSecondScreenLogin}
                        style={{ margin: 15 }}
                      >
                        TV Login Screen
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => IdpManager.logout()}
                        style={{ margin: 15 }}
                      >
                        Logout
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => this.setState({ showUserSettings: true })}
                        style={{ margin: 15 }}
                      >
                        Go to user settings
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() =>
                          this.attachEvent(IdpManager.showProfile({ modal: true }), 'Profile modal')
                        }
                        style={{ margin: 15 }}
                      >
                        Open user settings
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => this.setState({ showLiteRegistration: true })}
                        style={{ margin: 15 }}
                      >
                        Lite Registration
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => this.setState({ showMinimalLiteRegistration: true })}
                        style={{ margin: 15 }}
                      >
                        Minimal Lite Registration
                      </Button>
                      <Button
                        variant="contained"
                        onClick={IdpManager.loginWithStoredCredentials}
                        style={{ margin: 15 }}
                      >
                        Autologin with stored credentials
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => {
                          window.location = `${window.location.origin}/?pickFavouriteClub`;
                        }}
                        style={{ margin: 15 }}
                      >
                        Pick Favourite Clubs
                      </Button>
                      <div className="postRegistrationUrl-container">
                        <div className="postRegistrationUrl-title">
                          Post registration URL (default=current url):
                        </div>
                        <TextField
                          id="postRegistrationUrl"
                          onChange={(event) => this.setState({ redirectUrl: event.target.value })}
                          placeholder="e.g. https://gaming-int.uefa.com/en/uclpredictor/predictions/62"
                          margin="normal"
                          type="url"
                          style={{ minWidth: '320px' }}
                        />
                      </div>
                      {this.state.helpModalContent && (
                        <HelpModal onClose={() => this.setState({ helpModalContent: undefined })}>
                          {this.state.helpModalContent}
                        </HelpModal>
                      )}
                    </div>

                    <div style={{ marginTop: 50 }}>
                      <hr />
                      <h2>For development purposes:</h2>
                      <div>
                        <Button
                          variant="contained"
                          onClick={() =>
                            this.attachEvent(
                              IdpManager.showMandatoryProfileCompletion(),
                              'profileMandatoryCompletion',
                            )
                          }
                          style={{ margin: 15 }}
                        >
                          Finish Profile Screen
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() =>
                            this.attachEvent(
                              IdpManager.showProfileConfirmation(),
                              'profileConfirmation',
                            )
                          }
                          style={{ margin: 15 }}
                        >
                          Finish Profile After Email Screen
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() =>
                            this.attachEvent(
                              IdpManager.showVerificationPending(),
                              'Verification Pending',
                            )
                          }
                          style={{ margin: 15 }}
                        >
                          Verification pending screen
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() =>
                            this.attachEvent(
                              IdpManager.showVerificationSent(),
                              'Show Verification Sent',
                            )
                          }
                          style={{ margin: 15 }}
                        >
                          Verification sent screen
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() =>
                            this.attachEvent(
                              IdpManager.showDeclareForgottenPassword(),
                              'Forgotten password',
                            )
                          }
                          style={{ margin: 15 }}
                        >
                          Forgotten password
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() =>
                            this.attachEvent(
                              IdpManager.showDeclareForgottenPasswordConfirmation(),
                              'Forgotten password confirmation',
                            )
                          }
                          style={{ margin: 15 }}
                        >
                          Forgotten password confirmation
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() =>
                            this.attachEvent(
                              IdpManager.showResetForgottenPassword(),
                              'Reset forgotten password',
                            )
                          }
                          style={{ margin: 15 }}
                        >
                          Reset forgotten password
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() =>
                            this.attachEvent(
                              IdpManager.showResetForgottenPasswordSuccess(),
                              'Reset forgotten password success',
                            )
                          }
                          style={{ margin: 15 }}
                        >
                          Reset forgotten password success
                        </Button>
                      </div>
                    </div>

                    <div>
                      {user && user.isLogged() ? (
                        <div>
                          <p>You are logged in!</p>
                          <pre className="user-data">
                            <code>{JSON.stringify(user.toPlainObject(), null, 2)}</code>
                          </pre>
                        </div>
                      ) : (
                        <div>
                          <p>You are not logged in.</p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
            </div>

            {getDebugMode() && (
              <div className="event-container">
                <h3>Events</h3>
                <table className="event-table">
                  <thead>
                    <tr>
                      <th>Time</th>
                      <th>Name</th>
                      <th>Context</th>
                      <th>Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.events.map((eventData) => (
                      <tr key={eventData.id}>
                        <td>{moment(eventData.date, 'x').format('LTS')}</td>
                        <td>{eventData.event.name}</td>
                        <td>{eventData.context}</td>
                        <td>
                          <pre>
                            <code>
                              {typeof eventData.event.toPlainObject !== 'undefined'
                                ? JSON.stringify(eventData.event.toPlainObject(), null, 2)
                                : ''}
                            </code>
                          </pre>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default App;
