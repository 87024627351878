// @flow

export const IDP_SERVICES = [
  {
    name: 'Ad hoc',
    displayName: 'IDP Ad hoc',
    healthURL: {
      INT: 'https://idp-ad-hoc-int.uefa.com/actuator/health',
      STAGING: 'https://idp-ad-hoc-stg.uefa.com/actuator/health',
      PROD: 'https://idp-ad-hoc.uefa.com/actuator/health',
    },
    swagger: {
      INT: '',
      STAGING: '',
      PROD: '',
    },
  },
  {
    name: 'alt-bridge',
    displayName: 'IDP Alipay / Gigya Bridge',
    healthURL: {
      INT: 'https://idp-atl-bridge-int.uefa.com/actuator/health',
      STAGING: 'https://idp-atl-bridge-stg.uefa.com/actuator/health',
      PROD: 'https://idp-atl-bridge.uefa.com/actuator/health',
    },
    swagger: {
      INT: 'https://idp-service-int.uefa.com/swagger-ui.html',
      STAGING: 'https://idp-service-stg.uefa.com/swagger-ui.html',
      PROD: 'https://idp-service.uefa.com/swagger-ui.html',
    },
  },
  {
    name: 'anubis',
    displayName: 'IDP Anubis',
    healthURL: {
      INT: 'https://idp-anubis-int.uefa.com/actuator/health',
      STAGING: 'https://idp-anubis-stg.uefa.com/actuator/health',
      PROD: 'https://idp-anubis.uefa.com/actuator/health',
    },
    swagger: {
      INT: 'https://idp-anubis-int.uefa.com/swagger-ui.html',
      STAGING: 'https://idp-anubis-stg.uefa.com/swagger-ui.html',
      PROD: 'https://idp-anubis.uefa.com/swagger-ui.html',
    },
  },
  {
    name: 'BlueConic Reference Data',
    displayName: 'IDP BlueConic Reference Data',
    healthURL: {
      INT: 'https://idp-blueconic-reference-data-int.uefa.com/actuator/health',
      STAGING: 'https://idp-blueconic-reference-data-stg.uefa.com/actuator/health',
      PROD: 'https://idp-blueconic-reference-data.uefa.com/actuator/health',
    },
    swagger: {
      INT: '',
      STAGING: '',
      PROD: '',
    },
  },
  {
    name: 'bulk-deletion',
    displayName: 'IDP Bulk Deletion',
    healthURL: {
      INT: 'https://idp-bulk-deletion-int.uefa.com/actuator/health',
      STAGING: 'https://idp-bulk-deletion-stg.uefa.com/actuator/health',
      PROD: 'https://idp-bulk-deletion.uefa.com/actuator/health',
    },
    swagger: {
      INT: '',
      STAGING: '',
      PROD: '',
    },
  },
  {
    name: 'bulk-import',
    displayName: 'IDP Bulk Import',
    healthURL: {
      INT: 'https://idp-bulk-import-int.uefa.com/actuator/health',
      STAGING: 'https://idp-bulk-import-pre.uefa.com/actuator/health',
      PROD: 'https://idp-bulk-import.uefa.com/actuator/health',
    },
    swagger: {
      INT: '',
      STAGING: '',
      PROD: '',
    },
  },
  {
    name: 'connector',
    displayName: 'IDP Connector',
    healthURL: {
      INT: 'https://idp-connector-int.uefa.com/actuator/health',
      STAGING: 'https://idp-connector-stg.uefa.com/actuator/health',
      PROD: 'https://idp-connector.uefa.com/actuator/health',
    },
    swagger: {
      INT: 'https://idp-connector-int.uefa.com/swagger-ui.html',
      STAGING: 'https://idp-connector-stg.uefa.com/swagger-ui.html',
      PROD: 'https://idp-connector.uefa.com/swagger-ui.html',
    },
  },
  {
    name: 'DEP BlueConic',
    displayName: 'IDP DEP Blueconic',
    healthURL: {
      INT: 'https://idp-dep-blueconic-int.uefa.com/actuator/health',
      STAGING: 'https://idp-dep-blueconic-stg.uefa.com/actuator/health',
      PROD: 'https://idp-dep-blueconic.uefa.com/actuator/health',
    },
    swagger: {
      INT: 'https://idp-dep-blueconic-int.uefa.com/swagger-ui.html',
      STAGING: 'https://idp-dep-blueconic-stg.uefa.com/swagger-ui.html',
      PROD: 'https://idp-dep-blueconic.uefa.com/swagger-ui.html',
    },
  },
  {
    name: 'DEP Gigya',
    displayName: 'IDP DEP Gigya',
    healthURL: {
      INT: 'https://idp-dep-gigya-int.uefa.com/actuator/health',
      STAGING: 'https://idp-dep-gigya-stg.uefa.com/actuator/health',
      PROD: 'https://idp-dep-gigya.uefa.com/actuator/health',
    },
    swagger: {
      INT: '',
      STAGING: '',
      PROD: '',
    },
  },
  {
    name: 'DEP Selligent',
    displayName: 'IDP DEP Selligent',
    healthURL: {
      INT: 'https://idp-dep-selligent-int.uefa.com/actuator/health',
      STAGING: 'https://idp-dep-selligent-pre.uefa.com/actuator/health',
      PROD: 'https://idp-dep-selligent.uefa.com/actuator/health',
    },
    swagger: {
      INT: 'https://idp-dep-selligent-int.uefa.com/swagger-ui.html',
      STAGING: 'https://idp-dep-selligent-pre.uefa.com/swagger-ui.html',
      PROD: 'https://idp-dep-selligent.uefa.com/swagger-ui.html',
    },
  },
  {
    name: 'Facebook Lead Ad',
    displayName: 'IDP Facebook Lead Ad',
    healthURL: {
      INT: 'https://idp-facebook-lead-adapter-int.uefa.com/actuator/health',
      STAGING: 'https://idp-facebook-lead-adapter-stg.uefa.com/actuator/health',
      PROD: 'https://idp-facebook-lead-adapter.uefa.com/actuator/health',
    },
    swagger: {
      INT: 'https://idp-facebook-lead-adapter-int.uefa.com/swagger-ui.html',
      STAGING: 'https://idp-facebook-lead-adapter-stg.uefa.com/swagger-ui.html',
      PROD: 'https://idp-facebook-lead-adapter.uefa.com/swagger-ui.html',
    },
  },
  {
    name: 'Football entity search',
    displayName: 'IDP Football Search Entity',
    healthURL: {
      INT: 'https://idp-football-entity-search-int.uefa.com.uefa.com/actuator/health',
      STAGING: 'https://idp-football-entity-search-pre.uefa.com/actuator/health',
      PROD: 'https://idp-football-entity-search.uefa.com/actuator/health',
    },
    swagger: {
      INT: '',
      STAGING: '',
      PROD: '',
    },
  },
  {
    name: 'grunt',
    displayName: 'IDP Grunt',
    healthURL: {
      INT: 'https://idp-grunt-int.uefa.com/actuator/health',
      STAGING: 'https://idp-grunt-stg.uefa.com/actuator/health',
      PROD: 'https://idp-grunt.uefa.com/actuator/health',
    },
    swagger: {
      INT: 'https://idp-grunt-int.uefa.com/swagger-ui.html',
      STAGING: 'https://idp-grunt-stg.uefa.com/swagger-ui.html',
      PROD: 'https://idp-grunt.uefa.com/swagger-ui.html',
    },
  },
  {
    name: 'one-trust',
    displayName: 'IDP OneTrust Adapter',
    healthURL: {
      INT: 'https://idp-onetrust-adapter-int.uefa.com/actuator/health',
      STAGING: 'https://idp-onetrust-adapter-stg.uefa.com/actuator/health',
      PROD: 'https://idp-onetrust-adapter.uefa.com/actuator/health',
    },
    swagger: {
      INT: 'https://idp-onetrust-adapter-int.uefa.com/swagger-ui.html',
      STAGING: 'https://idp-onetrust-adapter-stg.uefa.com/swagger-ui.html',
      PROD: 'https://idp-onetrust-adapter.uefa.com/swagger-ui.html',
    },
  },
  {
    name: 'second-screen',
    displayName: 'IDP Second Screen',
    healthURL: {
      INT: 'https://idp-second-screen-int.uefa.com/actuator/health',
      STAGING: 'https://idp-second-screen-stg.uefa.com/actuator/health',
      PROD: 'https://idp-second-screen.uefa.com/actuator/health',
    },
    swagger: {
      INT: 'https://idp-second-screen-int.uefa.com/swagger-ui.html',
      STAGING: 'https://idp-second-screen-stg.uefa.com/swagger-ui.html',
      PROD: 'https://idp-second-screen.uefa.com/swagger-ui.html',
    },
  },
  {
    name: 'secutix-adapter',
    displayName: 'IDP Secutix Adapter',
    healthURL: {
      INT: 'https://idp-secutix-adapter-int.uefa.com/actuator/health',
      STAGING: 'https://idp-secutix-adapter-stg.uefa.com/actuator/health',
      PROD: 'https://idp-secutix-adapter.uefa.com/actuator/health',
    },
    swagger: {
      INT: 'https://idp-secutix-adapter-int.uefa.com/swagger-ui.html',
      STAGING: 'https://idp-secutix-adapter-stg.uefa.com/swagger-ui.html',
      PROD: 'https://idp-secutix-adapter.uefa.com/swagger-ui.html',
    },
  },
  {
    name: 'secutix-mapping',
    displayName: 'IDP Alipay / Secutix Mapping',
    healthURL: {
      INT: 'https://idp-atl-secutix-mapping-int.uefa.com/actuator/health',
      STAGING: 'https://idp-atl-secutix-mapping-stg.uefa.com/actuator/health',
      PROD: 'https://idp-atl-secutix-mapping.uefa.com/actuator/health',
    },
    swagger: {
      INT: 'https://idp-service-int.uefa.com/swagger-ui.html',
      STAGING: 'https://idp-service-stg.uefa.com/swagger-ui.html',
      PROD: 'https://idp-service.uefa.com/swagger-ui.html',
    },
  },
  {
    name: 'Selliblue Behaviors',
    displayName: 'IDP Selliblue Behaviors',
    healthURL: {
      INT: 'https://idp-selliblue-behaviors-int.uefa.com/actuator/health',
      STAGING: 'https://idp-selliblue-behaviors-stg.uefa.com/actuator/health',
      PROD: 'https://idp-selliblue-behaviors.uefa.com/actuator/health',
    },
    swagger: {
      INT: '',
      STAGING: '',
      PROD: '',
    },
  },
  {
    name: 'Selliblue Segments',
    displayName: 'IDP Selliblue Segments',
    healthURL: {
      INT: 'https://idp-selliblue-segments-int.uefa.com/actuator/health',
      STAGING: 'https://idp-selliblue-segments-stg.uefa.com/actuator/health',
      PROD: 'https://idp-selliblue-segments.uefa.com/actuator/health',
    },
    swagger: {
      INT: '',
      STAGING: '',
      PROD: '',
    },
  },
  {
    name: 'Selligent Adapter',
    displayName: 'IDP Selligent Adapter',
    healthURL: {
      INT: 'https://idp-selligent-adapter-int.uefa.com/actuator/health',
      STAGING: 'https://idp-selligent-adapter-stg.uefa.com/actuator/health',
      PROD: 'https://idp-selligent-adapter.uefa.com/actuator/health',
    },
    swagger: {
      INT: '',
      STAGING: '',
      PROD: '',
    },
  },
  {
    name: 'Selligent Merger',
    displayName: 'IDP Selligent Merger',
    healthURL: {
      INT: 'https://idp-selligent-merger-int.uefa.com/actuator/health',
      STAGING: 'https://idp-selligent-merger-pre.uefa.com/actuator/health',
      PROD: 'https://idp-selligent-merger.uefa.com/actuator/health',
    },
    swagger: {
      INT: '',
      STAGING: '',
      PROD: '',
    },
  },
  {
    name: 'Selligent Reference Data',
    displayName: 'IDP Selligent Reference Data',
    healthURL: {
      INT: 'https://idp-selligent-reference-data-int.uefa.com/actuator/health',
      STAGING: 'https://idp-selligent-reference-data-stg.uefa.com/actuator/health',
      PROD: 'https://idp-selligent-reference-data.uefa.com/actuator/health',
    },
    swagger: {
      INT: '',
      STAGING: '',
      PROD: '',
    },
  },
  {
    name: 'terminator',
    displayName: 'IDP Terminator',
    healthURL: {
      INT: 'https://idp-terminator-int.uefa.com/actuator/health',
      STAGING: 'https://idp-terminator-stg.uefa.com/actuator/health',
      PROD: 'https://idp-terminator.uefa.com/actuator/health',
    },
    swagger: {
      INT: '',
      STAGING: '',
      PROD: '',
    },
  },
];
