// @flow

import { getQueryParam, removeQueryParameters } from './helpers/urlHelpers';
import { queryParams, gigyaEnvironments, asyncStorageKeys } from '../config';

const DEFAULT_GIGYA_ENVIRONMENT = gigyaEnvironments.DEFAULT;

type Environment = $Values<typeof gigyaEnvironments>;

const getGigyaEnvironmentFromLocalStorage = (): ?string => {
  return localStorage.getItem(asyncStorageKeys.GIGYA_ENVIRONMENT);
};

const setGigyaEnvironmentInLocalStorage = (environment: Environment) => {
  localStorage.setItem(asyncStorageKeys.GIGYA_ENVIRONMENT, environment);
};

export const initGigyaEnvironment = (): Environment => {
  const gigyaEnvironmentFromQueryParam = getQueryParam(queryParams.ENV);
  if (
    gigyaEnvironmentFromQueryParam &&
    Object.values(gigyaEnvironments).includes(gigyaEnvironmentFromQueryParam)
  ) {
    setGigyaEnvironmentInLocalStorage(gigyaEnvironmentFromQueryParam);
    removeQueryParameters([queryParams.ENV]);
    return gigyaEnvironmentFromQueryParam;
  }
  const gigyaEnvironment = getGigyaEnvironmentFromLocalStorage();
  if (gigyaEnvironment) {
    return gigyaEnvironment;
  }
  setGigyaEnvironmentInLocalStorage(DEFAULT_GIGYA_ENVIRONMENT);
  return DEFAULT_GIGYA_ENVIRONMENT;
};

export const getCurrentGigyaEnvironment = (): Environment => {
  const environment = getGigyaEnvironmentFromLocalStorage();
  if (environment) {
    return environment;
  }
  return initGigyaEnvironment();
};
