// @flow

// $FlowFixMe
import { initializeApp } from 'firebase/app';
// $FlowFixMe
import { getAnalytics, logEvent } from 'firebase/analytics';

const googleAnalyticsAlreadyLoggedEvents = [];
const googleAnalyticsFirebaseAlreadyLoggedEvents = [];
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCQq_NymY0lxmqIrl8I9eeYpA6lHcRZX7c',
  authDomain: 'uefa-idp-demo-app.firebaseapp.com',
  databaseURL: 'https://uefa-idp-demo-app.firebaseio.com',
  projectId: 'uefa-idp-demo-app',
  storageBucket: 'uefa-idp-demo-app.appspot.com',
  messagingSenderId: '251027129209',
  appId: '1:251027129209:web:2eadcfca7eee4cc6b9a5a8',
  measurementId: 'G-R394TR4956',
};

const intervalTime = 3000; // miliseconds
let app = null;
let analytics = null;
let gaTimerId = null;

const checkGANewAnalytics = (context: ?string) => {
  const newGAEvents = getNewAnalytics();
  if (newGAEvents && analytics) {
    newGAEvents.forEach((e) => {
      logEvent(analytics, e.event, e);
    });
  }
};

const runGATimer = (context: ?string) => {
  if (gaTimerId === null) gaTimerId = setInterval(checkGANewAnalytics, intervalTime);
};

const stopGATimer = () => {
  if (gaTimerId) {
    clearInterval(gaTimerId);
    gaTimerId = null;
  }
};

const initializeGAFirebaseApp = () => {
  // Initialize Firebase
  if (!app) {
    app = initializeApp(firebaseConfig);
    analytics = getAnalytics(app);
  }
};

export const runGAFirebaseApp = () => {
  initializeGAFirebaseApp();
  runGATimer();
};

export const stopGAFirebaseApp = () => {
  stopGATimer();
};
export const checkAnalytics = () => {
  if (!window.dataLayer) return;
  const toLog = window.dataLayer.filter((e) => {
    if (!e.event) return false;

    if (e.event.match(/gtm/)) {
      return false;
    }
    if (
      googleAnalyticsAlreadyLoggedEvents.find((alreadyTrackedEvent) => e === alreadyTrackedEvent)
    ) {
      return false;
    }
    return true;
  });

  console.group('New analytics events');
  toLog.forEach((e) => {
    console.log(e);
    googleAnalyticsAlreadyLoggedEvents.push(e);
  });
  console.groupEnd();
};

export const getNewAnalytics = () => {
  const googleAnalyticsNewLoggedEvents = [];
  if (!window.dataLayer) return;
  const toLog = window.dataLayer.filter((e) => {
    if (!e.event) return false;

    if (e.event.match(/gtm/)) {
      return false;
    }
    if (
      googleAnalyticsFirebaseAlreadyLoggedEvents.find(
        (alreadyTrackedEvent) => e === alreadyTrackedEvent,
      )
    ) {
      return false;
    }
    return true;
  });

  toLog.forEach((e) => {
    googleAnalyticsFirebaseAlreadyLoggedEvents.push(e);
    googleAnalyticsNewLoggedEvents.push(e);
  });
  return googleAnalyticsNewLoggedEvents;
};
