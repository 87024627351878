// @flow
import UngapURLSearchParams from '@ungap/url-search-params';
import { parse, stringify } from 'query-string';

// This helper function is duplicated from idp-web-sdk
export const getTopLevelDomain = () => {
  const { domain } = document;
  if (!domain) return '';

  return domain.split('.').slice(-2).join('.');
};

export const getQueryParam = (param: string) => {
  const urlParams = new UngapURLSearchParams(window.location.search);
  return urlParams.get(param);
};

// This helper function is duplicated from idp-web-sdk
export const removeQueryParameters = (keysToRemove: string[]): void => {
  if (!Array.isArray(keysToRemove)) {
    console.error('You must provide a list of parameters (Array<string>) to remove');
    return;
  }

  /**
   * Transform the URL Query String to an Object and remove the properties
   * set as keysToRemove from it, then, transform it back to String
   */

  // https://github.com/sindresorhus/query-string#parsestring-options
  const parsedQueryString = parse(window.location.search.substring(1));
  const queryStringObj = removeFromObject(parsedQueryString, keysToRemove);
  // https://github.com/sindresorhus/query-string#stringifyobject-options
  const queryString = decodeURIComponent(stringify(queryStringObj));

  const urlSplit = window.location.href.split('?');
  const obj = {
    Title: document.title,
    // Set the URL and attach the remaining query string (if there's any)
    Url: queryString ? `${urlSplit[0]}?${queryString}` : urlSplit[0],
  };
  window.history.replaceState(obj, obj.Title, obj.Url);
};

const removeFromObject = (obj: Object, keysToRemove: string[]) => {
  keysToRemove.forEach((key: string) => {
    delete obj[key];
  });
  return obj;
};
