// @flow

import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import type { RouterHistory } from 'react-router-dom';

import './EmbeddedLoginRegister.css';

type Props = {|
  history: RouterHistory,
|};

type State = {|
  user: {
    profile?: {
      email: string,
      firstName: string,
      lastName: string,
    },
  },
|};

const IdpManager = window.IdpManager;
const IdpEventEmitter = IdpManager.getEventEmitter();

const EMBEDDED_CONTAINER_ID = 'idp-gigya-embedded-screen-container';

class EmbeddedLoginRegisterScreen extends React.PureComponent<Props, State> {
  state: State = {
    user: {},
  };

  componentDidMount() {
    IdpManager.onReady().then((user) => {
      if (user.isLogged()) {
        this.setState({ user });
      } else {
        // Show the login screen if the user have not logged in
        IdpManager.showLogin({ containerID: EMBEDDED_CONTAINER_ID });
      }
    });

    IdpEventEmitter.on('login', this.onLogin);
    IdpEventEmitter.on('logout', this.onLogout);
    IdpEventEmitter.on('request-close', this.onRequestClose);
  }

  componentWillUnmount() {
    IdpEventEmitter.off('login', this.onLogin);
    IdpEventEmitter.off('logout', this.onLogout);
    IdpEventEmitter.off('request-close', this.onRequestClose);
  }

  onRequestClose = async (event: *) => {
    console.log('Idp onRequestClose... ', event);
  };

  onLogin = (event: *) => {
    if (event.user) {
      this.setState({ user: event.user });
    }
  };

  onLogout = (event: *) => {
    this.setState({ user: {} });
    IdpManager.showLogin({ containerID: EMBEDDED_CONTAINER_ID });
  };

  showLoginModal = () => IdpManager.showLogin();

  handleLogout = () => IdpManager.logout();

  goBack = () => this.props.history.push('/');

  render() {
    const { user } = this.state;
    return (
      <>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              style={{ marginRight: '16px' }}
              onClick={this.goBack}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography className="screen-title" variant="h6">
              IDP - Embedded Login/Registration
            </Typography>
            {!user.profile && (
              <Button color="inherit" onClick={this.showLoginModal}>
                Login
              </Button>
            )}
            {user.profile && (
              <Button color="inherit" onClick={this.handleLogout}>
                Logout
              </Button>
            )}
          </Toolbar>
        </AppBar>
        {!user.profile && <div id={EMBEDDED_CONTAINER_ID} />}
        {user.profile && (
          <div id={EMBEDDED_CONTAINER_ID}>
            <Typography variant="h4" align="center" color="textPrimary" gutterBottom>
              Welcome {user.profile.firstName} {user.profile.lastName}
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
              You have successful logged in!
            </Typography>
            <Button variant="contained" onClick={this.handleLogout} style={{ margin: 15 }}>
              Logout
            </Button>
          </div>
        )}
      </>
    );
  }
}

export default EmbeddedLoginRegisterScreen;
