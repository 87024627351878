// @flow
import * as React from 'react';
import './EventBar.css';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';

import { checkAnalytics } from './analytics-checker';
import colorizeString from './colorizeString';

type Props = {|
  isDebugMode: boolean,
  toggleDebugMode: (SyntheticEvent<HTMLInputElement>) => void,
  isFirebase: boolean,
  toggleFirebase: (SyntheticEvent<HTMLInputElement>) => void,
|};

type State = {
  [string]: boolean,
};

const EventBadge = ({ name, shouldDisplay, color }) => {
  const shouldClass = shouldDisplay ? 'event-badge__displayed' : 'event-badge__hide';
  const badgeClassName = `event-badge ${shouldClass}`;
  return <div style={{ backgroundColor: color }} className={badgeClassName} />;
};

const EventIndicator = ({ name, shouldDisplay, color }) => {
  return (
    <div className="event-indicator">
      <div className="event-indicator__label" style={{ color: color }}>
        {name}
      </div>
      <EventBadge name={name} shouldDisplay={shouldDisplay} color={color} />
    </div>
  );
};

const ALL_EVENTS = [
  'fieldChanged',
  'error',
  'beforeScreenLoad',
  'afterScreenLoad',
  'beforeSubmit',
  'submit',
  'afterSubmit',
  'hide',
];

const analyticsButtonTooltip = `This button will display the latest analytics events in the browser console`;

const switchTooltip = `This switch will: \n
- Show hidden fields \n
- Display all the events in the browser console
`;

const switchFirebaseTooltip = `This switch will: \n
- Enable Firebase analytics \n
- So, you can see live events in Firebase DebugView
`;

const whatIsThisTooltip = `Below you will find the internal Gigya events. The little diodes will flash when they are emitted.\n
If you want the IDP events that we expose with this SDK, you need to enable the debug mode and check your browser console.`;

const WhatIsThis = () => (
  <div className="what-is-this__container">
    <div className="what-is-this__badge">
      <Tooltip title={whatIsThisTooltip} placement="right">
        <div>?</div>
      </Tooltip>
    </div>
  </div>
);

class EventBar extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    window.IdpManager.getEventEmitter().on('ready', () => {
      ALL_EVENTS.forEach((eventName) => {
        window.IdpManager._getGigyaEventEmitter().on(eventName, () => this.addBlink(eventName));
      });
    });
  }

  addBlink = (eventName: string) => {
    this.setState({ [eventName]: true });
    setTimeout(() => {
      this.setState({ [eventName]: false });
    }, 300);
  };

  render() {
    return (
      <div className="event-bar">
        <WhatIsThis />
        {ALL_EVENTS.map((eventName) => {
          const color = `#${colorizeString(eventName)}`;
          return (
            <EventIndicator
              key={eventName}
              color={color}
              name={eventName}
              shouldDisplay={this.state[eventName]}
            />
          );
        })}
        <button
          data-tip={analyticsButtonTooltip}
          onClick={checkAnalytics}
          className="analytics-checker__button"
        >
          Check analytics
        </button>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="debug-button">
            <Tooltip title={switchTooltip} placement="right">
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    color="secondary"
                    onChange={this.props.toggleDebugMode}
                    checked={this.props.isDebugMode}
                  />
                }
                label="Debug Mode"
                labelPlacement="top"
                color="secondary"
              />
            </Tooltip>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="debug-button">
            <Tooltip title={switchFirebaseTooltip} placement="right">
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    color="secondary"
                    onChange={this.props.toggleFirebase}
                    checked={this.props.isFirebase}
                  />
                }
                label="Firebase Mode"
                labelPlacement="top"
                color="secondary"
              />
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }
}

export default EventBar;
