// @flow

/* Remove our local gigya.css file import
 *
 * The function is called only on the staging environment
 * We want to use only the CSS hosted in Gigya on the staging environment,
 * because that is how the prod is going to be. We (probably?) won't host our own CSS.
 */
const removeLocalCss = () => {
  const linkElements = Array.from(document.getElementsByTagName('link'));
  linkElements.forEach((element) => {
    const href = element.getAttribute('href');
    if (href && href.match(/gigya\.css/)) {
      element.remove();
    }
  });
};

/* Remove the css hosted by Gigya
 *
 * This function is called locally on the dev environment
 * It is used to make sure the only css used is the one we create locally
 *
 * Why? Because sometimes you break stuff, and if you have the two CSS's
 * coexisting, then maybe your breaking changes are 'fixed' by the second version of the CSS (which doesn't have
 * the breaking changes yet).
 * Then when you synchronize the two (update the CSS on the Gigya console): it breaks.
 */
const removeGigyaCss = () => {
  const head = document.head;
  if (!head) return;

  /* Detect all changes made to the head of our DOM to detect style nodes and remove
   * the one we do not want
   * It is done this way because the style is added once the modal is opened */
  const observer = new MutationObserver((mutationsList, obs) => {
    mutationsList.forEach((mutation) => {
      mutation.addedNodes.forEach((node) => {
        if (node instanceof HTMLStyleElement && node.textContent.match(/.gigya-style-sheet/)) {
          node.remove();
        }
      });
    });
  });

  observer.observe(head, { childList: true, subtree: true });
};

export const removeExtraCss = () => {
  // In dev mode, we want our CSS only and not Gigya's
  if (process.env.NODE_ENV === 'development') {
    removeGigyaCss();
    return;
  }

  // In production mode, we want Gigya's CSS and not ours
  removeLocalCss();
};
