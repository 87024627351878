// @flow

import * as React from 'react';

type Props = {|
  close: Function,
  attachEvent: Function,
  showScreen: Function,
|};

class DisplayEmbeddedScreen extends React.PureComponent<Props> {
  componentDidMount() {
    const { attachEvent, showScreen } = this.props;
    attachEvent(
      showScreen({ campaign: 'testCampaign', selector: '#idp-gigya-embedded-screen-wrapper' }),
    );
  }

  close = () => {
    this.props.close();
  };

  render() {
    return (
      <div>
        <button onClick={this.close}>Return to the homepage</button>
        <div id="idp-gigya-embedded-screen-wrapper" />
      </div>
    );
  }
}

export default DisplayEmbeddedScreen;
