// @flow
import * as React from 'react';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { TableService } from './table';
import { IDP_SERVICES } from './config/idpServices';
import { HEALTH_STATUS } from './config/healtStatus';

type State = {|
  services: ServiceState[],
|};

export default class ServicesHealthChecker extends React.Component<{}, State> {
  state: State = {
    services: IDP_SERVICES.map((service: IdpService) => ({
      name: service.name,
      displayName: service.displayName,
      status: {
        INT: HEALTH_STATUS.NA,
        STAGING: HEALTH_STATUS.NA,
        PROD: HEALTH_STATUS.NA,
      },
      swagger: {
        INT: service.swagger.INT,
        STAGING: service.swagger.STAGING,
        PROD: service.swagger.PROD,
      },
    })),
  };

  componentDidMount() {
    this.getData();
  }

  getData() {
    IDP_SERVICES.forEach((service: IdpService, serviceIndex: number) => {
      Object.keys(service.healthURL).forEach(async (environment) => {
        try {
          const { data } = await axios(service.healthURL[environment]);
          const serviceStatus =
            data && data.status === HEALTH_STATUS.UP ? HEALTH_STATUS.UP : HEALTH_STATUS.DOWN;
          this.setStateForServiceAndEnv(serviceIndex, environment, serviceStatus);
        } catch (error) {
          if (!!error.isAxiosError && !error.response) {
            // Server timeout or connection error
            this.setStateForServiceAndEnv(serviceIndex, environment, HEALTH_STATUS.DOWN);
          } else {
            // Mostly service unavailable or gateway timeout
            this.setStateForServiceAndEnv(serviceIndex, environment, HEALTH_STATUS.NA);
          }
        }
      });
    });
  }

  setStateForServiceAndEnv = (
    serviceIndex: number,
    env: 'INT' | 'PROD' | 'STAGING',
    status: HealthStatus,
  ) => {
    this.setState((prevState) => {
      const nextState = cloneDeep(prevState);
      nextState.services[serviceIndex].status[env] = status;

      return nextState;
    });
  };

  render() {
    return (
      <Grid container spacing={4} justify="center">
        <Grid item xs={11} md={10}>
          <Paper style={{ marginTop: '30px' }}>
            <TableService services={this.state.services} />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}
