// @flow

import * as React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './index.css';
import { removeExtraCss } from './remove-extra-css';

import { getQueryParam } from './services/helpers/urlHelpers';
import { getGigyaApiKey } from './getGigyaApiKey';
import { initGigyaEnvironment } from './services/context';

import { queryParams } from './config';

import App from './App';
import ServicesHealthChecker from './screens/monitoring';
import EmbeddedLoginRegisterScreen from './screens/embedded-login-register/EmbeddedLoginRegister';

const idpEnvironment = initGigyaEnvironment();
const IdpManager = window.IdpManager;

IdpManager.init({
  apiKey: getGigyaApiKey(idpEnvironment),
  locale: getQueryParam(queryParams.LOCALE),
});

const AppRouter = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={App} />
      <Route path="/monitoring" component={ServicesHealthChecker} />
      <Route path="/embedded" component={EmbeddedLoginRegisterScreen} />
    </Switch>
  </Router>
);

const rootElement = document.getElementById('root');
if (rootElement) {
  ReactDOM.render(<AppRouter />, rootElement);
}

removeExtraCss();
