// @flow

import * as React from 'react';
import { Modal } from '@material-ui/core';
import { getCurrentGigyaEnvironment } from '../../services/context';

import './PersonalizeLoginRegister.css';

type Props = {|
  close?: () => void,
  startScreen?: 'login' | 'register',
|};

const IdpManager = window.IdpManager;
const IdpEventEmitter = IdpManager ? IdpManager.getEventEmitter() : null;
const CONTAINER_ID_SELECTOR = 'idp-screens-wrapper-container';

export const PersonalizeLoginRegister: (Props) => React.Node = ({
  close,
  startScreen = 'login',
}) => {
  const [openModal, toggleOpenModal] = React.useState(false);
  const styleEnvironment = {
    backgroundColor: getCurrentGigyaEnvironment() === 'default' ? '' : 'salmon',
  };

  React.useLayoutEffect(() => {
    toggleOpenModal(true);
  }, []);

  React.useEffect(() => {
    if (openModal) {
      handleStartScreen();
    }
  }, [openModal]);

  const handleStartScreen = () => {
    switch (startScreen) {
      case 'login':
        IdpManager.showLogin({ containerID: CONTAINER_ID_SELECTOR });
        break;
      case 'register':
        IdpManager.showRegister({ containerID: CONTAINER_ID_SELECTOR });
        break;
      default:
        IdpManager.showScreen(startScreen, { containerID: CONTAINER_ID_SELECTOR });
    }
    handleLogin();
  };

  const handleLogin = () => {
    if (!IdpEventEmitter) return;
    IdpEventEmitter.on('login', (event: *) => {
      if (!event.user) return;
      closeHandler();
    });
  };

  const closeHandler = () => {
    toggleOpenModal(false);
    if (typeof close !== 'function') return;
    close();
  };

  return (
    <Modal keepMounted open={openModal} onClose={closeHandler}>
      <div className="ReactModal__Overlay">
        <div className="ReactModal__Content ReactModal__Content--after-open login__modal">
          <button className="close closeIcon" onClick={closeHandler} />
          <div
            id="host-site-screens-wrapper-container"
            style={styleEnvironment}
            className="login-modal__text-container"
          >
            <div className="login-modal__text-container-inner">
              <div className="login-modal__title">
                Can host site add custom content to IDP screens?
              </div>
              <div className="login-modal__text">
                <div className="login-modal__image" />
                <div>
                  <p>
                    Yes, off course host site can add &nbsp;
                    <b>Free content, Images, and anything next to IDP screens.</b>
                    <br />
                    This can be done by host site team by creating a modal wrapper container (div)
                    that has two child containers:
                  </p>
                  <ul>
                    <li>One container for the host site content</li>
                    <li>
                      Another container for IDP screens (Login, Register, Forget Password, ...)
                    </li>
                  </ul>
                  <p>
                    off course, host site team should handle this through css design. we can provide
                    same css design we applied here.
                  </p>
                </div>
              </div>
              <div className="login-modal__text">
                <div className="login-modal__image login-modal__image--second" />
                <p>
                  For more details, follow this link :&nbsp;
                  <a href="https://wikicom.uefa.ch/display/ID/Displaying+personalized+screen">
                    Peronalize IDP Screens
                  </a>
                </p>
              </div>
            </div>
          </div>
          {openModal && (
            <div id="idp-screens-wrapper-container" className="login-modal__login-container" />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PersonalizeLoginRegister;
